export const HEADER_LINKS = [
  { name: 'Home', pathname: '/' },
  { name: 'About', pathname: '/about' },
  { name: 'Products', pathname: '/products' },
  { name: 'Blogs', pathname: '/blogs' },
  { name: 'Contact Us', pathname: '/contactus' },
  { name: 'Sign In', pathname: '/signin' },
  { name: 'Sign Up', pathname: '/signup' },
  // ToDo: enable this once sign-out feature is implemented
  // { name: 'Sign Out', pathname: '' },
]

export const HEADER_HEIGHT = 62
