import { gql } from '@apollo/client'

const ProductsQuery = gql`
  query products {
    products {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`

export default ProductsQuery
