import React from 'react'
import styled from 'styled-components'
import { getFadeInTransition } from 'utils/helpers'

const FadeInTransition = styled(({ component, ...props }) =>
  React.cloneElement(component, props)
)`
  ${({ classnames, timeout, style }) =>
    getFadeInTransition(classnames, timeout, style)}
`

export default FadeInTransition
