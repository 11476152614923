import React from 'react'
import Container from './styles'

const Footer = () => (
  <Container>
    <span className="h-align-center">
      <span>Copyright@2020 Hyderabad INDIA, All rights reserved.</span>
    </span>
  </Container>
)

export default Footer
