import {
  MESSAGE_SEVERITY,
  PROFILE_ERROR_KEYS,
  ProfileValidationError,
} from 'types'

const invalidEmailID = (message: string): ProfileValidationError => ({
  type: MESSAGE_SEVERITY.ERROR,
  message,
  key: PROFILE_ERROR_KEYS.INVALID_EMAIL_ID,
})

const validateEmailID = (emailId: string): ProfileValidationError => {
  // ToDo: should do email-length without @*.com validation
  if (!emailId) {
    return invalidEmailID("emailId can't be null")
  }
  if (
    // eslint-disable-next-line
    !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      emailId
    )
  ) {
    return invalidEmailID('emailId is not valid')
  }

  return null
}

export default validateEmailID
