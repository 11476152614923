import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import flattenDeep from 'lodash/flattenDeep'
import { toast } from 'react-toastify'
import get from 'lodash/get'
import AddProductsMutation from 'graphql/mutations/AddProductsMutation'
import * as Style from './styles'

const AddProduct = () => {
  const history = useHistory()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [addProductsMutation, { data }] = useMutation(AddProductsMutation)

  const handleSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    addProductsMutation({
      variables: {
        input: [
          {
            name,
            description,
          },
        ],
      },
    })
  }

  useEffect(() => {
    if (!data?.addProducts) return

    const { validationErrors, success, result } = data.addProducts

    if (validationErrors) {
      flattenDeep(validationErrors).forEach((error) => {
        toast.error(get(error, 'message'), {
          autoClose: 6000,
        })
      })
    } else if (success && result?.affectedRows === 1) {
      toast.success('Successfully inserted product details')
      history.push('/products')
    } else {
      toast.warn('Something is Wrong, please retry again')
    }
  }, [data, history])

  return (
    <Style.Container className="h-align-center" onSubmit={handleSubmit}>
      <form>
        <Style.Label>
          Product Name *
          <input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            name="name"
            required
          />
        </Style.Label>
        <Style.Label>
          Product Description *
          <input
            id="description"
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            name="description"
            required
          />
        </Style.Label>
        <div className="h-align-center h-marginT-large">
          <input type="submit" value="Submit" />
        </div>
      </form>
    </Style.Container>
  )
}

export default AddProduct
