import React from 'react'
import { Container, Bar } from './styles'

type HamburgerProps = {
  className?: string
  onClick?: () => void
  color?: string
}

const Hamburger = ({
  className = '',
  onClick = () => {},
  color = 'black',
}: HamburgerProps) => (
  <Container className={className} onClick={onClick}>
    <span className="h-sr-only">toggle navigation</span>
    <Bar className="icon-bar" color={color} />
    <Bar className="icon-bar" color={color} />
    <Bar className="icon-bar" color={color} />
  </Container>
)

export default Hamburger
