import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  About,
  Blogs,
  ContactUs,
  Footer,
  Header,
  Home,
  Products,
  SignIn,
  SignUp,
  AddProduct,
  ResetPassword,
} from './containers'
import { getMediaQuery, createClassName } from './utils/helpers'
import { Container, Content } from './styles'
import GlobalStyle from './globalStyles'

const App = () => {
  const isMobile = useMediaQuery({ query: getMediaQuery('<sm') })

  return (
    <Container>
      <GlobalStyle />
      <Header />
      <Content
        className={createClassName({
          lg: !isMobile,
          sm: isMobile,
        })}
      >
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/blogs" component={Blogs} />
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/contactus" component={ContactUs} />
          <Route exact path="/products" component={Products} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/resetpassword" component={ResetPassword} />
          <Route exact path="/products/register" component={AddProduct} />
        </Switch>
      </Content>
      <Footer />
      <ToastContainer />
    </Container>
  )
}

export default App
