import {
  MESSAGE_SEVERITY,
  PROFILE_ERROR_KEYS,
  ProfileValidationError,
} from 'types'

const invalidName = (message: string): ProfileValidationError => ({
  type: MESSAGE_SEVERITY.ERROR,
  message,
  key: PROFILE_ERROR_KEYS.INVALID_NAME,
})

const validateName = (name: string): ProfileValidationError => {
  return !name.length ? invalidName("name can't be null") : null
}

export default validateName
