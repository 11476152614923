import { createGlobalStyle } from 'styled-components'
import { defaultTheme as theme } from 'themes/default'

/**
 * Do not use the below reserved z-index values
 *  1. 999
 *  2. 998
 *  3. 997
 */
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: white;
    font-family: Open-Sans, Helvetica, Sans-Serif;
  }

  html, body, #kybing-web-app {
    height: -webkit-fill-available;
  }

  .h-container {
    @media (min-width: 768px) {
      width: 768px;
    }
    @media (min-width: 992px) {
      width: 992px;
    }
    @media (min-width: 1200px) {
      width: 1200px;
    }
  }

  //-------------------------------- spacing helpers
  .h-margin-none {
    margin: 0 !important;
  }

  // Margin-All
  .h-margin-small {
    margin: ${theme.space.small}px !important;
  }
  .h-margin-medium {
    margin: ${theme.space.medium}px !important;
  }
  .h-margin-large {
    margin: ${theme.space.large}px !important;
  }

  // Margin-X
  .h-marginX-small {
    margin-left: ${theme.space.small}px !important;
    margin-right: ${theme.space.small}px !important;
  }
  .h-marginX-medium {
    margin-left: ${theme.space.medium}px !important;
    margin-right: ${theme.space.medium}px !important;
  }
  .h-marginX-large {
    margin-left: ${theme.space.large}px !important;
    margin-right: ${theme.space.large}px !important;
  }

  // Margin-Left
  .h-marginL-small {
    margin-left: ${theme.space.small}px !important;
  }
  .h-marginL-medium {
    margin-left: ${theme.space.medium}px !important;
  }
  .h-marginL-large {
    margin-left: ${theme.space.large}px !important;
  }

  // Margin-Right
  .h-marginR-small {
    margin-right: ${theme.space.small}px !important;
  }
  .h-marginR-medium {
    margin-right: ${theme.space.medium}px !important;
  }
  .h-marginR-large {
    margin-right: ${theme.space.large}px !important;
  }

  // Margin-Y
  .h-marginY-small {
    margin-top: ${theme.space.small}px !important;
    margin-bottom: ${theme.space.small}px !important;
  }
  .h-marginY-medium {
    margin-top: ${theme.space.medium}px !important;
    margin-bottom: ${theme.space.medium}px !important;
  }
  .h-marginY-large {
    margin-top: ${theme.space.large}px !important;
    margin-bottom: ${theme.space.large}px !important;
  }

  // Margin-Top
  .h-marginT-small {
    margin-top: ${theme.space.small}px !important;
  }
  .h-marginT-medium {
    margin-top: ${theme.space.medium}px !important;
  }
  .h-marginT-large {
    margin-top: ${theme.space.large}px !important;
  }

  // Margin-Bottom
  .h-marginB-small {
    margin-bottom: ${theme.space.small}px !important;
  }
  .h-marginB-medium {
    margin-bottom: ${theme.space.medium}px !important;
  }
  .h-marginB-large {
    margin-bottom: ${theme.space.large}px !important;
  }

  .h-padding-none {
    padding: 0 !important;
  }
  .h-padding-small {
    padding: ${theme.space.small}px !important;
  }
  .h-padding-medium {
    padding: ${theme.space.medium}px !important;
  }
  .h-padding-large {
    padding: ${theme.space.large}px !important;
  }

  //-------------------------------- display helpers
  .h-overflow-hidden {
    overflow: hidden;
  }
  .h-flex {
    display: flex;
  }

  //-------------------------------- alignment helpers
  .h-align-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .h-flex-directionY {
    flex-direction: column
  }

  //-------------------------------- text-helpers
  .h-text-center {
    text-align: center
  }
  .h-text-center {
    text-align: center
  }

  //-------------------------------- accessibility helpers
  .h-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  //-------------------------------- cursor helpers
  .h-cursor-pointer {
    cursor: pointer !important;
  }
`

export default GlobalStyle
