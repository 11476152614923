import {
  MESSAGE_SEVERITY,
  PROFILE_ERROR_KEYS,
  ProfileValidationError,
} from 'types'

const invalidPassword = (message: string): ProfileValidationError => ({
  type: MESSAGE_SEVERITY.ERROR,
  message,
  key: PROFILE_ERROR_KEYS.INVALID_PASSWORD,
})

const validatePassword = (password: string): ProfileValidationError => {
  return !password.length ? invalidPassword("password can't be null") : null
}

export default validatePassword
