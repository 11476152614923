import React, { useEffect, useState, useCallback } from 'react'
import { toast } from 'react-toastify'
import { CSSTransition } from 'react-transition-group'
import { useMediaQuery } from 'react-responsive'
import Helmet from 'react-helmet'
import { Overlay, CSSTransitionHelper } from 'components'
import { HEADER_LINKS } from 'constants/header'
import {
  Container,
  Content,
  NavsContainer,
  LogoContainer,
  NavLinkContainer,
  HamburgerContainer,
  LinksContainer,
  LogoTitle,
} from './styles'
import logo from 'assets/images/logo.png'
import { getMediaQuery, createClassName } from 'utils/helpers'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, db, logout } from '../../auth/firebase'

// ToDo: use enum instead of string
type HeaderProps = {
  isFixed?: boolean
  title?: 'KYBING'
}

const Header = ({ isFixed = true, title = 'KYBING' }: HeaderProps) => {
  const isMobile = useMediaQuery({ query: getMediaQuery('<sm') })
  const [isOpen, setIsOpen] = useState(false)
  const [user, loading, error] = useAuthState(auth)
  const [userEmail, setUserEmail] = useState('')

  const fetchUserName = useCallback(() => {
    const fetchUserData = async () => {
      try {
        const query = await db
          .collection('users')
          .where('uid', '==', user?.uid)
          .get()

        console.log('KYB user', user)
        const data = await query.docs[0]?.data()
        console.log('KYB User Data', data)

        setUserEmail(data?.email || user?.email || '')
      } catch (err) {
        toast.error('An error occurred while fetching user data')
      }
    }

    fetchUserData()
  }, [setUserEmail, user])

  // ToDo: Optimize
  useEffect(() => {
    if (loading || userEmail !== '') return

    fetchUserName()
  }, [userEmail, loading, fetchUserName])

  useEffect(() => {
    toast.error(error)
  }, [error])

  const onLogout = useCallback(async () => {
    setIsOpen(false)

    // ToDo: Show Loading-Component
    await logout()

    setUserEmail('')
  }, [setUserEmail])

  // console.log('userEmail -> ', userEmail)

  return (
    <>
      {isMobile && isOpen && (
        <Helmet>
          <body className="h-overflow-hidden" />
        </Helmet>
      )}
      {isMobile && (
        <CSSTransitionHelper
          inProp={isOpen}
          classNames="overlay"
          timeout={500}
          style={{ display: 'none', opacity: 0 }}
        >
          <Overlay onClick={() => setIsOpen(false)} />
        </CSSTransitionHelper>
      )}
      {isMobile && (
        <HamburgerContainer
          className={createClassName({ transition: isOpen })}
          onClick={() => {
            setIsOpen((val) => !val)
          }}
        />
      )}
      <CSSTransition in={isOpen} classNames="header" timeout={500}>
        <Container
          className={createClassName({
            transition: isOpen,
            lg: !isMobile,
            sm: isMobile,
          })}
          isFixed={isFixed}
        >
          <Content
            className={createClassName({
              lg: !isMobile,
              sm: isMobile,
            })}
          >
            <NavsContainer
              className={createClassName('h-container', {
                lg: !isMobile,
                transition: isOpen,
                sm: isMobile,
              })}
            >
              <NavLinkContainer
                className={createClassName('logo-container', {
                  sm: isMobile,
                })}
                to="/"
                exact
                onClick={() => setIsOpen(false)}
              >
                <LogoContainer>
                  <img src={logo} alt="logo" />
                </LogoContainer>
                <LogoTitle>{title}</LogoTitle>
              </NavLinkContainer>
              <LinksContainer
                className={createClassName('', {
                  lg: !isMobile,
                  transition: isOpen,
                  sm: isMobile,
                })}
              >
                {HEADER_LINKS.map(({ name, pathname }) => {
                  if (userEmail && ['Sign In', 'Sign Up'].includes(name)) {
                    return null
                  }

                  return (
                    <NavLinkContainer
                      key={name}
                      className={createClassName({
                        lg: !isMobile,
                        sm: isMobile,
                      })}
                      activeClassName="selected"
                      to={pathname}
                      exact
                      onClick={() => setIsOpen(false)}
                    >
                      <CSSTransitionHelper
                        classNames="navlink"
                        inProp={isOpen}
                        timeout={400}
                      >
                        <span>{name}</span>
                      </CSSTransitionHelper>
                    </NavLinkContainer>
                  )
                })}
                {/* {<span>{userEmail}</span>} */}
                {/* 
                  'NavLinkContainer' is used for 'Home, About, Signin etc..' AND 'Logout' Header-Buttons
                  Here it can be said that there is a duplicate code of 'NavLinkContainer' for 'Logout' Header-Button.
                  Use only one 'NavLinkContainer' code for 'Home, About, Signin etc..' AND 'Logout' Header-Buttons
                 */}
                {userEmail && (
                  <NavLinkContainer
                    className={createClassName({
                      lg: !isMobile,
                      sm: isMobile,
                    })}
                    to="/"
                    exact
                    onClick={onLogout}
                  >
                    <CSSTransitionHelper
                      classNames="navlink"
                      inProp={isOpen}
                      timeout={400}
                    >
                      <span>Logout</span>
                    </CSSTransitionHelper>
                  </NavLinkContainer>
                )}
              </LinksContainer>
            </NavsContainer>
          </Content>
        </Container>
      </CSSTransition>
    </>
  )
}

export default Header
