import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { Hamburger } from 'components'

const logoIconWidth = 50

export const Container = styled.header<{ isFixed?: boolean }>`
  ${({ isFixed }) => (isFixed ? 'position: fixed;' : '')}
  z-index: 998;

  &.lg {
    width: 100%;
  }

  &.sm {
    height: 100vh;

    &.header-enter,
    &.header-enter-done,
    &.header-exit {
      width: 50%;
    }
  }
`

export const Content = styled.div`
  &.lg {
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: ${({ theme }) => theme.palette.primary.main};
    box-shadow: 0px 0px 5px 0px ${({ theme }) => theme.shadow};
  }

  &.sm {
    height: 100%;
  }
`

export const NavsContainer = styled.div`
  &.lg {
    display: flex;
    justify-content: space-around;
  }

  &.sm {
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    max-width: 0;
    background-color: ${({ theme }) => theme.palette.primary.main};
    box-shadow: 0px 0px 5px 0px ${({ theme }) => theme.shadow};
    transition: all 0.4s ease-in-out;

    &.transition {
      max-width: 500px;
    }
  }
`

export const LinksContainer = styled.div`
  &.lg {
    display: flex;
  }

  &.sm {
    display: flex;
    flex-direction: column;
  }
`

export const LogoContainer = styled.div`
  width: ${logoIconWidth}px;
  height: ${logoIconWidth}px;
  border-radius: 100%;
  overflow: hidden;

  img {
    width: ${logoIconWidth}px;

    @media (prefers-reduced-motion: no-preference) {
      animation: logo-img-spin infinite 20s linear;
    }

    @keyframes logo-img-spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }
`

export const LogoTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.primary.contrastText};
`

// ToDo: focus is causing to stay the hover-color even after mouseout, so disabling it temporarily
// &:focus,
export const NavLinkContainer = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 15px;
  font-weight: bold;
  text-decoration: none;
  white-space: nowrap;
  border: 2px solid transparent;

  ${({ theme }) => {
    const { dark, light, contrastText } = theme.palette.primary

    return `
      color: ${contrastText};
      
      &:hover {
        background-color: ${dark};
        color: ${contrastText};
      }

      &:active {
        background-color: ${light};
        color: ${contrastText};
      }
    
      &.selected {
        color: ${contrastText};
    
        &.lg {
          border-bottom: 2px solid ${contrastText};
        }
    
        &.sm {
          border-right: 2px solid ${contrastText};
        }
      }
    `
  }}

  &.logo-container {
    grid-gap: 10px;
    justify-content: center;
    padding: 0 15px;

    &.sm {
      padding: 15px 0;
      border-bottom: 1px solid white;
    }
  }
`

export const ExternalNavLinkContainer = styled.a`
  display: flex;
  align-items: center;
  padding: 15px;
  grid-gap: 5px;
  font-weight: bold;
  text-decoration: none;
  white-space: nowrap;

  ${({ theme }) => {
    const { dark, light, contrastText } = theme.palette.primary

    return `
      color: ${contrastText};

      svg path {
        fill: ${contrastText};
      }
    
      &:hover {
        background-color: ${dark};
        color: ${contrastText};
      }
    
      &:active {
        background-color: ${light};
        color: ${contrastText};
      }
    `
  }}

  svg {
    margin-top: -1px;
  }
`

export const HamburgerContainer = styled(Hamburger)`
  position: absolute;
  margin-top: 13px;
  right: 0;
  margin-right: 15px;
  border: 0;
`
