import styled from 'styled-components'

export const Container = styled.button`
  padding: 10px;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 5px;
  opacity: 0.8;
  z-index: 999;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
  &:hover {
    opacity: 1;
  }

  &.transition .icon-bar {
    &:nth-child(2) {
      background-color: gold;
      transform: rotate(-36deg) translate(-4px, 6px);
    }
    &:nth-child(3) {
      opacity: 0;
    }
    &:nth-child(4) {
      background-color: gold;
      transform: rotate(36deg) translate(-4px, -6px);
    }
  }
`

export const Bar = styled.span`
  display: block;
  width: 25px;
  height: 2px;
  border-radius: 1px;
  background-color: ${({ color }) => color};
  margin-top: 5px;
  transition: 0.4s;

  &:nth-child(2) {
    margin-top: 0;
  }
  &:nth-child(3) {
    width: 17px;
    margin-left: 4px;
  }
`
