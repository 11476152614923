import styled from 'styled-components'

// ToDo: import zIndex from props.theme
const Container = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 997;
`

export default Container
