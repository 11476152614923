import styled from 'styled-components'

export const Container = styled.div`
  margin: 10px;
`

export const Label = styled.div`
  margin-bottom: 10px;

  input {
    margin-left: 10px;
  }
`
