import React from 'react'
import Container from './styles'

type OverlayProps = {
  className?: string
  onClick?: () => void
}

const Overlay = ({ className = '', onClick = () => {} }: OverlayProps) => (
  <Container className={className} onClick={onClick} />
)

export default Overlay
