import styled from 'styled-components'

export const Table = styled.table`
  border-collapse: collapse;
  margin: 15px 0;

  tr {
    border-spacing: 0;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
`

export const Error = styled.div`
  font-weight: bold;

  span {
    color: ${({ theme }) => theme.palette.error.main};
  }
`
