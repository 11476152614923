import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { toast } from 'react-toastify'
import { Button } from 'components'
import { useAuthState } from 'react-firebase-hooks/auth'
import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from '../../auth/firebase'
import validateEmailID from 'utils/validateEmailID'
import validatePassword from 'utils/validatePassword'
import validateName from 'utils/validateName'
import { CommonColors } from 'themes/themTypes'

const SignUp = () => {
  const history = useHistory()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [user, loading, error] = useAuthState(auth)

  useEffect(() => {
    // ToDo: Show Loading-Component
    if (loading) return

    if (user) history.push('/')

    console.error(error)
  }, [user, loading, error, history])

  const register = () => {
    if (!name) alert('Please enter name')

    registerWithEmailAndPassword(name, email, password)
  }

  /**
   * ToDo: 'isValidName, isValidEmailID & isValidPassword' has similar code, optimize it to single function.
   */
  const isValidName = (): boolean => {
    const { message: errorMessage } = validateName(name) || {}

    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 6000 })
    }

    return !errorMessage
  }

  const isValidEmailID = (): boolean => {
    const { message: errorMessage } = validateEmailID(email) || {}

    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 6000 })
    }

    return !errorMessage
  }

  const isValidPassword = (): boolean => {
    const { message: errorMessage } = validatePassword(password) || {}

    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 6000 })
    }

    return !errorMessage
  }

  const isNotValid = (): boolean => {
    const errors = [isValidName(), isValidEmailID(), isValidPassword()]
    return errors.some((value) => value === false)
  }

  return (
    <Container>
      <RegisterContainer>
        <Name
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Full Name"
        />
        <Email
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <Password
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <Register
          onClick={() => {
            if (isNotValid()) return

            register()
          }}
        >
          Register
        </Register>
        <RegisterWithGoogle
          color={CommonColors.info}
          onClick={signInWithGoogle}
        >
          Register with Google
        </RegisterWithGoogle>
        <div className="h-text-center">
          Already have an account? <Link to="/">Register</Link> now.
        </div>
      </RegisterContainer>
    </Container>
  )
}

export default SignUp

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Input = css`
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
`

const Name = styled.input`
  ${Input}
`

const Email = styled.input`
  ${Input}
`

const Password = styled.input`
  ${Input}
`

const Register = styled(Button)`
  margin-bottom: 10px;
`

const RegisterWithGoogle = styled(Button)`
  margin-bottom: 10px;
`
