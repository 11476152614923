import React from 'react'
import { useQuery } from '@apollo/client'
import ProductsQuery from 'graphql/queries/ProductsQuery'
import * as Styled from './styles'

type Product = {
  id: string
  name: string
  description: string
}

const Products = () => {
  const { loading, error, data } = useQuery(ProductsQuery)

  return (
    <div className="h-align-center h-flex-directionY">
      <h2>List of Projects</h2>
      {loading && <div>Loading...</div>}
      {error && (
        <Styled.Error>
          Error Query failed <span>{error?.message}</span>
        </Styled.Error>
      )}
      <Styled.Table>
        <tbody>
          <tr>
            <th></th>
            <th>name</th>
            <th>description</th>
          </tr>
          {data?.products.map(
            ({ id, name, description }: Product, key: number) => (
              <tr key={id}>
                <td>{key + 1}</td>
                <td>{name}</td>
                <td>{description}</td>
              </tr>
            )
          )}
        </tbody>
      </Styled.Table>
    </div>
  )
}

export default Products
