import styled from 'styled-components'

// ToDo: Footer background-color is not looking good, change it.
const Container = styled.footer`
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  background-color: ${({ theme }) => theme.palette.default.dark};
  color: ${({ theme }) => theme.palette.default.contrastText};
`

export default Container
