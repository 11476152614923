import React from 'react'
import { CSSTransition } from 'react-transition-group'
import FadeInTransition from './styles'

type CSSTransitionHelperProps = {
  inProp: boolean
  classNames?: string
  timeout?: number
  style?: React.CSSProperties
  children: React.ReactNode
}

const CSSTransitionHelper = ({
  inProp,
  classNames = 'transitionClassName',
  timeout = 500,
  style = {},
  children,
}: CSSTransitionHelperProps) => (
  // ToDo: Remove ts-ignore
  // @ts-ignore
  <CSSTransition in={inProp} classNames={classNames} timeout={timeout}>
    <FadeInTransition
      classnames={classNames}
      timeout={timeout}
      style={style}
      component={children}
    />
  </CSSTransition>
)

export default CSSTransitionHelper
