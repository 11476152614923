import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { ApolloProvider } from '@apollo/client'
import apolloClient from './apolloClient'
import App from './App'
import { defaultTheme as theme } from './themes/default'
import { createPalette } from 'themes/themeUtils'
import reportWebVitals from './reportWebVitals'

const KB_PALETTE = {
  primary: '#AF555C',
}

theme.palette = createPalette(KB_PALETTE)

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('kybing-web-app')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
