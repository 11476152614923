export type ObjType = {
  [key: string]: string
}

export type CustomObjType<V> = {
  [key: string]: V
}

export type CreateClassName = {
  [key: string]: boolean
}

// ToDo: Change this real style-props type-checker
export type StyleObj = {
  [key: string]: string
}

export enum MESSAGE_SEVERITY {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  ALERT = 'ALERT',
}

export enum PROFILE_ERROR_KEYS {
  INVALID_NAME = 'INVALID_NAME',
  INVALID_USER_ID = 'INVALID_USER_ID',
  INVALID_EMAIL_ID = 'INVALID_EMAIL_ID',
  INVALID_PASSWORD = 'INVALID_PASSWORD',
}

export type ProfileValidationError = {
  type: MESSAGE_SEVERITY
  message: string
  key: PROFILE_ERROR_KEYS
} | null
