import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { toast } from 'react-toastify'
import { Button } from 'components'
import { useAuthState } from 'react-firebase-hooks/auth'
import validateEmailID from 'utils/validateEmailID'
import { auth, sendPasswordResetEmail } from '../../auth/firebase'

const ResetPassword = () => {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [user, loading, error] = useAuthState(auth)

  useEffect(() => {
    // ToDo: Show Loading-Component
    if (loading) return

    if (user) history.push('/')

    console.error(error)
  }, [user, loading, error, history])

  const isValidEmailID = (): boolean => {
    const { message: errorMessage } = validateEmailID(email) || {}

    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 6000 })
    }

    return !errorMessage
  }

  const isNotValid = (): boolean => {
    const errors = [isValidEmailID()]
    return errors.some((value) => value === false)
  }

  return (
    <Container>
      <ResetContainer>
        <Email
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <Reset
          className="reset__btn"
          onClick={() => {
            if (isNotValid()) return

            sendPasswordResetEmail(email)
          }}
        >
          Send password reset mail
        </Reset>
        <div className="h-text-center">
          Don't have an account? <Link to="/register">Register</Link> now.
        </div>
      </ResetContainer>
    </Container>
  )
}

export default ResetPassword

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ResetContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Input = css`
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
`

const Email = styled.input`
  ${Input}
`

const Reset = styled(Button)`
  margin-bottom: 10px;
`
