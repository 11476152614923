import { gql } from '@apollo/client'

const AddProductsMutation = gql`
  mutation addProducts($input: [ProductInput!]!) {
    addProducts(input: $input) {
      success
      result {
        fieldCount
        fieldCount
        affectedRows
        insertId
        serverStatus
        warningCount
        message
        protocol41
        changedRows
      }
      validationErrors {
        message
      }
    }
  }
`

export default AddProductsMutation
