import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { toast } from 'react-toastify'
import { Button } from 'components'
import { useAuthState } from 'react-firebase-hooks/auth'
import {
  auth,
  signInWithEmailAndPassword,
  signInWithGoogle,
} from '../../auth/firebase'
import validateEmailID from 'utils/validateEmailID'
import validatePassword from 'utils/validatePassword'
import { CommonColors } from 'themes/themTypes'

const SignIn = () => {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [user, loading, error] = useAuthState(auth)

  useEffect(() => {
    // ToDo: Show Loading-Component
    if (loading) return

    if (user) history.push('/')

    console.error(error)
  }, [user, loading, error, history])

  /**
   * ToDo: 'isValidEmailID & isValidPassword' has similar code, optimize it to single function.
   */
  const isValidEmailID = (): boolean => {
    const { message: errorMessage } = validateEmailID(email) || {}

    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 6000 })
    }

    return !errorMessage
  }

  const isValidPassword = (): boolean => {
    const { message: errorMessage } = validatePassword(password) || {}

    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 6000 })
    }

    return !errorMessage
  }

  const isNotValid = (): boolean => {
    const errors = [isValidEmailID(), isValidPassword()]
    return errors.some((value) => value === false)
  }

  return (
    <Container>
      <LoginContainer>
        <Email
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <Password
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <Login
          onClick={() => {
            if (isNotValid()) return

            signInWithEmailAndPassword(email, password)
          }}
        >
          Login
        </Login>
        <LoginWithGoogle color={CommonColors.info} onClick={signInWithGoogle}>
          Login with Google
        </LoginWithGoogle>
        <div className="h-marginB-medium h-text-center">
          <Link to="/resetpassword">Forgot Password</Link>
        </div>
        <div className="h-text-center">
          Don't have an account? <Link to="/register">Register</Link> now.
        </div>
      </LoginContainer>
    </Container>
  )
}

export default SignIn

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Input = css`
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
`

const Email = styled.input`
  ${Input}
`

const Password = styled.input`
  ${Input}
`

const Login = styled(Button)`
  margin-bottom: 10px;
`

const LoginWithGoogle = styled(Button)`
  margin-bottom: 10px;
`
