import firebase from 'firebase/compat/app'

const firebaseConfig = {
  apiKey: 'AIzaSyDuQaEwVC8KILfW16Z_4qPye6ZXm17vGqU',
  authDomain: 'kybing-web.firebaseapp.com',
  databaseURL: 'https://kybing-web.firebaseio.com',
  projectId: 'kybing-web',
  storageBucket: 'kybing-web.appspot.com',
  messagingSenderId: '150323559561',
  appId: '1:150323559561:web:433873a860d49f2522da58',
  measurementId: 'G-397PTXWKKL',
}

const firebaseApp = firebase.initializeApp(firebaseConfig)

export { firebase }
export default firebaseApp
