import styled from 'styled-components'
import { HEADER_HEIGHT } from './constants/header'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
`

export const Content = styled.div`
  height: -webkit-fill-available;

  &.lg {
    margin-top: ${HEADER_HEIGHT}px;
  }
`
