import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
`

export const Img = styled.img`
  @media (prefers-reduced-motion: no-preference) {
    animation: Logo-img-spin infinite 20s linear;
  }

  @keyframes Logo-img-spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`

export const Title = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  text-shadow: 2px 2px 5px white;
`
